import appStyles from '../../App.module.css';
import Container from 'react-bootstrap/Container';
import Asset from '../../components/Asset';
import Profile from './Profile';
import { useProfileData } from '../../contexts/ProfileDataContext';

const PopularProfiles = ({ mobile }) => {
	const { popularProfiles } = useProfileData();

	return (
		<Container
			className={`${appStyles.Content} ${
				mobile && 'd-lg-none text-center mb-3'
			}`}
		>
			<p>Most Followed Profiles</p>
			{popularProfiles.results.length ? (
				mobile ? (
					<div className="d-flex justify-content-around">
						{popularProfiles.results.slice(0, 4).map(profile => (
							<Profile key={profile.id} profile={profile} mobile />
						))}
					</div>
				) : (
					popularProfiles.results.map(profile => (
						<Profile key={profile.id} profile={profile} />
					))
				)
			) : (
				<Asset spinner />
			)}
		</Container>
	);
};

export default PopularProfiles;
