import { useState } from 'react';

import styles from '../../styles/Comment.module.css';

import Media from 'react-bootstrap/Media';
import { Link } from 'react-router-dom';

import Avatar from '../../components/Avatar';
import { MoreDropdown } from '../../components/MoreDropdown';
import CommentEditForm from './CommentEditForm';

import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { axiosRes } from '../../api/axiosDefaults';

const Comment = props => {
	const {
		profile_id,
		profile_image,
		owner,
		updated_at,
		content,
		id,
		setPosts,
		setComments,
	} = props;
	const is_owner = useCurrentUser()?.username === owner;
	const [showEditForm, setShowEditForm] = useState(false);

	const handleDelete = async () => {
		try {
			await axiosRes.delete(`comments/${id}/`);
			setPosts(prevPost => ({
				results: [
					{
						...prevPost.results[0],
						comments_count: prevPost.results[0].comments_count - 1,
					},
				],
			}));
			setComments(prevComments => ({
				...prevComments,
				results: prevComments.results.filter(comment => comment.id !== id),
			}));
		} catch (err) {
			// console.log(err);
		}
	};

	return (
		<>
			<hr />
			<Media>
				<Link to={`/profiles/${profile_id}`}>
					<Avatar src={profile_image} />
				</Link>
				<Media.Body className="align-self-center ml-2">
					<span className={styles.Owner}>{owner}</span>
					<span className={styles.Date}>{updated_at}</span>
					{showEditForm ? (
						<CommentEditForm
							id={id}
							profile_id={profile_id}
							content={content}
							profileImage={profile_image}
							setComments={setComments}
							setShowEditForm={setShowEditForm}
						/>
					) : (
						<p>{content}</p>
					)}
				</Media.Body>
				{is_owner && !showEditForm && (
					<MoreDropdown
						handleEdit={() => setShowEditForm(true)}
						handleDelete={handleDelete}
					/>
				)}
			</Media>
		</>
	);
};

export default Comment;
